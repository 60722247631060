<script setup>
import { onAuthStateChanged } from '@firebase/auth'
import { useUserStore } from '@/store/user.js'
import { useRouter } from 'vue-router'
import { isStaging, isPreProd } from './helpers/environment'
import { initializePaddle } from './helpers/paddle.js'
import { onMounted, computed } from 'vue'
import { auth } from '@/plugins/firebase'
import { useHead } from '@vueuse/head'
const router = useRouter()

const getBaseUrl = () => {
  return isStaging
    ? 'https://staging.holy-bible.app'
    : isPreProd
      ? 'https://preprod.holy-bible.app'
      : 'https://holy-bible.app'
}

useHead({
  title: 'Explore Your Faith with Biblical Insights | Bible Path',
  link: computed(() => [
    {
      rel: 'canonical',
      href: getBaseUrl() + router.currentRoute.value.path.replace(/\/$/, ''),
    },
    { rel: 'me', href: 'https://www.instagram.com/biblepath.app' },
    { rel: 'me', href: 'https://www.youtube.com/@BiblePath.chatai' },
  ]),
  meta: [
    {
      name: 'description',
      content:
        'Connect with faith through Bible Chat, Audio Bible, and personalized reflections. Explore scriptures, prayers, and daily inspirations with the Holy Bible app.',
    },
    {
      property: 'og:title',
      content: 'Discover Daily Inspiration with the Holy Bible App',
    },
    {
      property: 'og:description',
      content:
        'Deepen your faith with Bible Path. Enjoy scripture readings, prayers, and personalized reflections, all in one app designed to guide your spiritual journey.',
    },
    { property: 'og:url', content: 'https://holy-bible.app/onboarding' },
    { property: 'og:type', content: 'website' },
    {
      property: 'og:image',
      content: 'https://holy-bible.app/images/og-onboarding.jpg',
    },
  ],
})
onMounted(() => {
  initializePaddle()
  const userStore = useUserStore()
  onAuthStateChanged(auth, async user => {
    if (isStaging) {
      //console.log('::USER::', user)
    }
    if (user) {
      localStorage.setItem('iuli', true)
      userStore.setUser(user, false, router.options.history.state.current)
    } else {
      localStorage.removeItem('iuli')
      userStore.setUser(null)
    }
  })
})
</script>

<template>
  <RouterView />
</template>
